import { render, staticRenderFns } from "./ModalModifyDocumentsVJ.vue?vue&type=template&id=10cc3911&scoped=true"
import script from "./ModalModifyDocumentsVJ.vue?vue&type=script&lang=js"
export * from "./ModalModifyDocumentsVJ.vue?vue&type=script&lang=js"
import style0 from "./ModalModifyDocumentsVJ.vue?vue&type=style&index=0&id=10cc3911&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10cc3911",
  null
  
)

export default component.exports